* 


{
    box-sizing: border-box;
    margin: 0;
  }
  
  :root {
    --heights: 50vh;
    --widths: 100%;
  }
  
  .slider-container {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  
  .active {
    display: inline-block;
  }
  
  .inactive {
    display: none;
  }
  
  .slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  
  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 50px;
    position: absolute;
    text-align: center;
    top: 40%;
    z-index: 10;
  }
  
  .slide-text {
    top: 65%;
    font-size: 2rem;
  }
  
  .prev,
  .next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }

  .container{
    font-family:'Tilt Neon';
    white-space: nowrap;
  }
  
  .slider-container:hover .prev,.slider-container:hover .next {
    color: black;
    /* color: black; */
  }
  .imageItems{
    border-radius: 25px;
  }
  
  .slider-container:hover .prev:hover,
  .slider-container:hover .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  .btn,
  .hoover{
    background-color: rgb(0, 102, 255);
    color: rgb(255, 255, 255);
  }
  
  .btn,
  .btn-primary{
    
    color: rgb(0, 102, 255);
    background-color: rgb(255, 255, 255);
  }
  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 200;
  }
  
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .slider-container:hover .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  /* .active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  } */
  
  .slider-container:hover .dot{
    background-color: rgba(0, 0, 0, 0.3);
  }
  .slider-container:hover .active-dot{
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .play-pause {
    color: black;
  }
/* Lateral list Css */
.horizontal-list {
  display: flex;      /* Display children horizontally */
  list-style: none;   /* Remove default list styles */
  padding: 0;         /* Remove default padding */
}

.horizontal-list ul {
  display: flex;      /* Display list items horizontally */
  padding: 0;         /* Remove default padding */
  flex-direction: row;
}

.horizontal-list li {
  margin-right: 10px; /* Add space between list items */
  display: flex;
  flex-direction: row;
}

#secondary ol{
  list-style-type: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  /* width: 210px;
  height: 200px; */
  overflow: auto;
  /* border: 3px solid royalblue; */
  /* Setup the counter. */
  counter-reset: yourVariableName;
}
#secondary li:before{
  /* Advance the number. */
  counter-increment: yourVariableName;
  /* Use the counter number as content. */
  content: '' counter(yourVariableName) '. ';
  color: rgb(180, 65, 225);
  /* font-family: 'Impact'; */
}

.btn .btn-primary .lg-btn{
  background-color: rgb(0, 0, 255);
}

.horizontal-list li:last-child {
  margin-right: 0;    /* Remove margin from the last item */
}
/* End of lateral List Css */
@tailwind base;
@tailwind components;
@tailwind utilities;
