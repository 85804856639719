Wrapper .animate .darkBg {
    background-color: rgb(68, 67, 67);
}
.ListGroup .ListGroup.Item{
    background-color: darkgrey;
}

hr.style1{
    border-top: 1px dashed #8c8b8b;
}

.listGroup{
    border-top: none;
}
.arrow{
    background-color: rgb(54, 51, 51);
}

#header{
    box-shadow: 0px 16px 48px 0px rgba(196, 195, 195, 0.176);
    /* box-shadow: 0px 16px 48px 0px rgba(0,0,0,0.176); */
}

.element {
    width: 100%;
    height: 70px;
    background-color: rgb(68, 67, 67);
    color: aliceblue;
    border-radius: 10px;
    box-shadow: 0px 16px 48px 0px rgba(0,0,0,0.176);
  }

.d-flex {
    background-color: rgb(68, 67, 67);
    color: #f0f8ff;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
    
}

pill .primary{
    background-color: rgb(148, 198, 245);
}

NavLink .navlink{
    background-color: red;
}

/* a{
    display: block;
} */

.listgrp{
    
    font-family:'Tilt Neon';
    box-shadow: pt-dark-elevation-shadow-1;
    background-color: rgb(125, 126, 126);
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.btn-circle.btn-xl { 
    width: 50px; 
    height: 50px; 
    padding: 2px 10px; 
    border-radius: 35px; 
    font-size: 30px; 
    text-align: center; 
    padding-right: 10px;
    /* background-color: rgb(44, 98, 245); */
} 