.circle {
    /* display: flex;
    width: 17px;
    height: 17px; */
    /* background-color: rgb(255, 255, 255); */
    
    
  }

  /* .circle rect:hover1{
    background-color: rgb(136,165,217);
    border-radius: 50%;
  } */
  .in-circle{
    width: fit-content;
    height: fit-content;
  }

  svg.plus{
    /* display: flex; */
    padding-left: 9px;
    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
    background-color: #c0d1f1;
    /* width: 15px;
    height: 15px; */
    /* color: #fff; */
    /* -webkit-border-radius: 15px;
    -moz-border-radius: 15px; */

    border-radius: 50%;
  }